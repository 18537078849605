import React, { useEffect } from "react"
import { Layout } from "@components/layout"
import {
  processingPaymentAndOrder,
  PurchaseCompleted,
  PurchaseFailed,
} from "@components/ecommerce"
import {
  CartCtx,
  CustomerCtx,
  useActions,
  useContextState,
} from "@components/contexted"

// import './styles/summary.scss'

const Message = ({ location }) => {
  const { order, payment } = useContextState(CartCtx, ["order", "payment"])
  let orderId = new URLSearchParams(location.search).get("id")

  return (
    <React.Fragment>
      {payment.phase === "success" ? (
        <PurchaseCompleted orderId={orderId} />
      ) : (
        <PurchaseFailed
          orderId={orderId}
          msg={payment.msg}
          phase={payment.phase}
        />
      )}
    </React.Fragment>
  )
}

const Summary = ({ pageContext, location }) => {
  const {
    changePaymentStatus,
    setOrderFromApi,
    resetCart,
  } = useActions(CartCtx, [
    "changePaymentStatus",
    "setOrderFromApi",
    "resetCart",
  ])
  useEffect(() => {
    processingPaymentAndOrder({
      location,
      changePaymentStatus,
      setOrderFromApi,
      resetCart,
    })
  }, [])
  const { page } = pageContext
  return (
    <Layout {...page}>
      <Message location={location} />
    </Layout>
  )
}
export default Summary
